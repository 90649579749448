@import "./colors.scss";

#login-form {
  background: white;
  color: $charcoal;
  padding: 3rem;
  display:float;
  width:fit-content;
  margin: 20vh auto;
  text-align: center;

  p {
    font-size:1.5rem;
    font-weight: bold; 
  }
  input {
    display: block;
    margin: 1rem 0;
    width: 280px;
    max-width:100%;
    padding: .5rem 1rem;
  }

  .error-message {
    color: $dark-coral;
  }

  button {
    padding: 1.5rem;
    width: 280px;
    max-width: 100%;
    border-radius: 0;
    background-color: transparent;
    display: inline-flex;
    border: 1px solid $charcoal;
    justify-content: center;
    text-align:center;
    white-space: nowrap;
    cursor: pointer;
    background: $charcoal;
    color: white;
    border: 1px solid white;
  }
}