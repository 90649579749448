@import "./colors.scss";

header {
  padding: 1rem;

  a {
    padding: 1rem 0.5rem;
    margin: 0 1.5rem;
    color: white;
    &:hover {
      color: $dark-coral;
    }
    &.active {
      color: $dark-coral;
      border-bottom: 1px solid $dark-coral;
    }
  }

  .button {
    border-radius: 2px;
    min-width: 120px;
    &:hover {
      opacity: .9;
    }
  }
}