@import "./colors.scss";

#status-bar {
  padding: 1rem 1rem;
  margin-bottom: 0;
  color: white;

  .level-left,
  .level-right {
    min-width: 320px
  }

  .error-message {
    color: $dark-coral;
  }
}