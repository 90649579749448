@font-face {
  font-family: "Ivar Display Condensed";
  src: url("../fonts/IvarDisplayCondensed-Regular.woff2");
}

@font-face {
  font-family: "Moderat Regular";
  src: url("../fonts/Moderat-Regular.woff2");
}

@font-face {
  font-family: "Moderat Bold";
  src: url("../fonts/Moderat-Bold.woff2");
}