@import "./colors.scss";

.error {
  margin-top: 3rem;
  padding: 1rem;
  background: $dark-coral;
  color: $charcoal;
}

#time-selector {
  background: white;
  margin: 0rem 1rem;
  padding: 2rem;
  overflow: auto;
  border-radius: 2px;
  color: $charcoal;

  .navButton {
    cursor: pointer;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
    &.disabled {
      cursor: default;
      opacity: 0.2;
    }
  }

  #time-table {
    min-width: 1000px;

    #date-container {
      background: $concrete;
      margin: 2rem 0rem 1rem;
      .columns {
        padding: 0 1.5rem;
        .column {
          font-size: 0.8rem;
          line-height: 1.4;
          font-weight: 700;
          text-align: center;
          color: $charcoal;

          span {
            cursor: pointer;
            font-weight: normal;
            &:hover {
              color: $dark-coral;
            }
          }
        }
      }
    }

    #time-container {
      margin-bottom: 2rem;
      .columns {
        margin: 0;
        .column {
          padding: 0.25rem;
          border-right: 1px solid $light-gray;
          &:last-child {
            border-right: none;
          }
          .time {
            padding: 0.5rem;
            margin-top: 0.5rem;
            display: block;
            text-align: center;
            cursor: pointer;
            &:hover {
              background-color: $concrete;
            }
            input[type="checkbox"] {
              display: inline;
              vertical-align: middle;
              margin-right: 1rem;
              width: 1.2rem;
              height: 1.2rem;
              cursor: pointer;
              &:checked {
                background: $charcoal;
                &:after {
                  display: block;

                  background: $charcoal;
                }
              }
            }
            span {
              display: inline-block;
              vertical-align: middle;
              width: 68px;
              text-align: left;
            }
          }
          .disabled {
            color: $gray;
            cursor: initial;
            background: $concrete;
            height: 100%;
            justify-content: center;
            align-items: center;
            display: flex;
            min-height: 50vh;
          }
        }
      }
    }
  }
}
