@import "./colors.scss";

.table {
  background: white;
  margin: 0 1rem;
  padding: 2rem;
  overflow: auto;
  border-radius: 2px;

  .columns {
    // min-width: 1500px;

    &.header {
      font-size: 0.8rem;
      letter-spacing: 0.08rem;
      font-weight: bold;
      border-bottom: 1px solid $light-gray;
    }

    &.gray-bg {
      background: $concrete;
    }
  }

  .badge {
    padding: 0.5rem;
    background-color: #ddd;
    border-radius: 4px;
    display: inline;
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: bold;
  }

  .initials {
    display: inline-flex;
    margin-right: 0.5rem;
    background: #ddd;
    border-radius: 50%;
    font-size: 0.9rem;
    font-weight: 600;
    height: 48px;
    width: 48px;
    align-items: center;
    justify-content: center;
  }

  button {
    &.button.black {
      background: black;
      color: white;
      border-radius: 4px;
      border: none;
    }
  }
}
