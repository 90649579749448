@import "bulma/sass/utilities/mixins.sass";

.modal-container {
  position: fixed;
  overflow: hidden;
  z-index: 99999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);

  .close-icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 1;
    cursor: pointer;
  }

  .content {
    position: absolute;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    background: white;
    width: 500px;
    max-width: 95vw;
    max-height: 95vh;
    overflow: auto;
    border-radius: 4px;

    .header,
    .children {
      margin-bottom: 0;
      padding: 1.5rem 2rem;
    }

    select {
      display: block;
      margin-top: 0.5rem;
      width: 100%;
      padding: 0.8rem;
      border: 1px solid #999;
      border-radius: 4px;
    }

    button {
      width: 100%;
      padding: 0.8rem;
      background: black;
      color: white;
      border: none;
      border-radius: 4px;
      margin-top: 1rem;
      cursor: pointer;

      &:hover {
        background: #333;
      }

      &:disabled,
      &[disabled] {
        background: #999;
      }
    }
  }

  &.dark {
    background: #000;

    .content {
      background: #000;
      color: white;

      h1 {
        color: white;
        font-weight: 400;
      }
    }
  }

  &.full-screen {
    background: #fff;

    .content {
      background: #fff;
      top: 1rem;
      transform: inherit;
      left: 0;
      width: 100%;
      height: 100%;
      max-width: 100%;

      h1 {
        font-weight: 400;
      }
    }
  }
}
